$(document).ready(function(){
	var pagenr = 1;
	if (window.location.hash) {
		var page = window.location.hash.substr(1);
		pagenr = page.replace('page', '');
	}
	fetch_itemslist(pagenr);
});

function fetch_itemslist(pagenr){
	$('.itemslist').each(function(index, el){
		var options = {};
		if(get_attr_value(el, 'data-itemsperpage')){
			options.itemsperpage = get_attr_value(el, 'data-itemsperpage');
		}
		if(get_attr_value(el, 'data-modulename')){
			options.modulename = get_attr_value(el, 'data-modulename');
		}
		if(get_attr_value(el, 'data-layout')){
			options.layout = get_attr_value(el, 'data-layout');
		}
		if(get_attr_value(el, 'data-breadcrumbs')){
			options.breadcrumbs = get_attr_value(el, 'data-breadcrumbs');
		}
		if(get_attr_value(el, 'data-linked')){
			options.linked = get_attr_value(el, 'data-linked');
		}
		if (get_attr_value(el, 'data-filter')) {
			options.filter = get_attr_value(el, 'data-filter');
		}
		if(get_attr_value(el, 'data-parentid')){
			options.parentid = get_attr_value(el, 'data-parentid');
		}
		if(get_attr_value(el, 'data-search')){
			options.search = get_attr_value(el, 'data-search');
		}
		if(get_attr_value(el, 'data-order')){
			options.order = get_attr_value(el, 'data-order');
		}
		if(get_attr_value(el, 'data-pageid')){
			options.pageid = get_attr_value(el, 'data-pageid');
		}

		options.pagenr = pagenr;
		get_items(options);
	});
}

function get_attr_value(el, attr_name){
	return $(el).attr(attr_name);
}

function get_items(options){
	$.get('/ajax/'+site_id+'/'+lang+'/'+options.modulename, options, function(data){
		$('.itemslist').html(data);
		$('.pager-ajax a.page-link').click(function(){
			var page = $(this).attr('href');
			var pagenr = page.replace('#page', '');
			fetch_itemslist(pagenr);
		});
	});
}