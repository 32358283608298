var package_price_default = 0;
var package_price_group = 0;
var amount_adults = 0;
var amount_children = 0;
var amount_elderly = 0;
var booking_cost = parseFloat($('meta[name=booking_cost]').attr("content"));
var discount_children = parseFloat($('meta[name=discount_children]').attr("content"));
var discount_seniors = parseFloat($('meta[name=discount_seniors]').attr("content"));
var discount_wholesalers = parseFloat($('meta[name=discount_wholesalers]').attr("content"));
var currency = parseFloat($('meta[name=currency]').attr("content"));

  function formatDate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1;

    return [date.getFullYear(),
        (mm>9 ? '' : '0') + mm,
        (dd>9 ? '' : '0') + dd
       ].join('-');
  }

$(document).ready(function(){
    package_price_default = parseFloat($('[data-info="package_default_price"]').val());
    package_price_group = parseFloat($('[data-info="package_group_price"]').val());
    amount_adults = parseFloat($('[data-component="change_adults"]').val());
    amount_children = parseFloat($('[data-component="change_children"]').val());
    amount_elderly = parseFloat($('[data-component="change_elderly"]').val());

    $('[data-component="change_startdate"]').change(function(){
        $enddate = $('[data-component="change_enddate"]');
        var duration = parseInt($enddate.attr('data-duration'));
        if(duration > 0){
            var startdate = $(this).val();
            var dat = new Date(startdate);
            dat.setDate(dat.getDate() + duration);
            $enddate.val(formatDate(dat));
        }
    });

    $('[data-component="change_upsale"]').change(calc_price);
    $('[data-component="change_adults"]').change(function(){
        amount_adults = parseInt($(this).val());
        calc_price();
    });
    $('[data-component="change_children"]').change(function(){
        amount_children = parseInt($(this).val());
        calc_price();
    });
    $('[data-component="change_elderly"]').change(function(){
        amount_elderly = parseInt($(this).val());
        calc_price();
    });

    calc_price();
});

function calc_price(){
    var total_amount = amount_adults+amount_children+amount_elderly;
    var price = 0;
    var adults_price = 0;
    var children_price = 0;
    var elderly_price = 0;

    if(total_amount >= 12){
        adults_price = package_price_group*amount_adults;
        children_price = package_price_group*(1-(discount_children/100))*amount_children;
        elderly_price = package_price_group*(1-(discount_seniors/100))*amount_elderly;
    }else{
        adults_price = package_price_default*amount_adults;
        children_price = package_price_default*(1-(discount_children/100))*amount_children;
        elderly_price = package_price_default*(1-(discount_seniors/100))*amount_elderly;
    }

    if (currency == 'CNY') {
        var decimals = 0;
    }else{
        var decimals = 2;
    }

    adults_price = parseFloat(adults_price.toFixed(decimals));
    children_price = parseFloat(children_price.toFixed(decimals));
    elderly_price = parseFloat(elderly_price.toFixed(decimals));
    booking_cost = parseFloat(booking_cost.toFixed(decimals));

    price =  adults_price + children_price + elderly_price + booking_cost;

    $('[data-component="change_upsale"]:checked').each(function(i, el){
        if(total_amount >= 12){
            price += parseFloat($(el).attr('data-price-group'));
        }else{
            price += parseFloat($(el).attr('data-price'));
        }
    });

    var done = [];
    if ($('[data-currency]').length > 0) {
        $('[data-currency]').each(function(i, el){
            var currency = $(el).attr('data-currency');
            if (currency == 'CNY') {
                var decimals = 0;
            }else{
                var decimals = 2;
            }

            if (!done.includes(currency)) {
                done.push(currency);
                var turns = 0;
                var custom_total = 0;

                $.get('/currency/convert/'+adults_price+'/EUR/'+currency, function(result){
                    var my_price = parseFloat(result.toFixed(decimals));
                    $('[data-component="adults_price_custom"][data-currency="'+currency+'"]').html(my_price);
                    custom_total += my_price;
                    turns++;
                    check_total_price(turns, custom_total, currency);
                });
                $.get('/currency/convert/'+children_price+'/EUR/'+currency, function(result){
                    var my_price = parseFloat(result.toFixed(decimals));
                    $('[data-component="children_price_custom"][data-currency="'+currency+'"]').html(my_price);
                    custom_total += my_price;
                    turns++;
                    check_total_price(turns, custom_total, currency);
                });
                $.get('/currency/convert/'+elderly_price+'/EUR/'+currency, function(result){
                    var my_price = parseFloat(result.toFixed(decimals));
                    $('[data-component="elderly_price_custom"][data-currency="'+currency+'"]').html(my_price);
                    custom_total += my_price;
                    turns++;
                    check_total_price(turns, custom_total, currency);
                });
                $.get('/currency/convert/'+booking_cost+'/EUR/'+currency, function(result){
                    var my_price = parseFloat(result.toFixed(decimals));
                    $('[data-component="bookingcost_price_custom"][data-currency="'+currency+'"]').html(my_price);
                    custom_total += my_price;
                    turns++;
                    check_total_price(turns, custom_total, currency);
                });
            }
        });
    }else{
        $('[data-component="total_adults"]').html(parseFloat(adults_price).toFixed(decimals));
        $('[data-component="total_children"]').html(parseFloat(children_price).toFixed(decimals));
        $('[data-component="total_elderly"]').html(parseFloat(elderly_price).toFixed(decimals));
        $('[data-component="total_price"]').html(parseFloat(price).toFixed(decimals));
        $('[data-component="total_bookingcost"]').html(parseFloat(booking_cost).toFixed(decimals));
    }
}

function check_total_price(turns, custom_total, currency){
    if (currency == 'CNY') {
                var decimals = 0;
            }else{
                var decimals = 2;
            }
                    if (turns == 4) {
                        $('[data-component="total_price_custom"][data-currency="'+currency+'"]').html(parseFloat(custom_total).toFixed(decimals));
                    }
}