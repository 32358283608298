var api_key = 'a2cb36a190584ced9e36a06b7d75f569';
const client = new ApiAi.ApiAiClient({accessToken: api_key});

var chatbot_active = false;

$('document').ready(function(){

	// Chat active
	if (Cookies.get('chatbot_active') != undefined && Cookies.get('chatbot_active') == 'true') {
		chatbot_active = true;
	} else {
		chatbot_active = false;
	}

	if (chatbot_active) {
		$('.chat-wrapper').addClass('open');
	}


	if (Cookies.get('chatbot_session') != undefined) {
		client.setSessionId(Cookies.get('chatbot_session'));
		loadChat();
	}

	$('.open-chat').click(function(){
		$('.chat-wrapper').addClass('open');

		var $input = $('.chat__input input');
		var input_length = $input.val().length;

		$input[0].focus();
		$input[0].setSelectionRange(input_length, input_length);

		Cookies.set('chatbot_active','true');
	});
	$('.close-chat').click(function(){
		$('.chat-wrapper').removeClass('open');
		Cookies.remove('chatbot_session');
		clearChat();

		Cookies.set('chatbot_active','false');
	});


	$('.chat__input button').click(onSendClicked);

	$(".chat__input input").on('keyup', function (event) {
		if (event.keyCode == 13) { onSendClicked(event); }
	});
});



/* handlers */

function onSendClicked (event) {
	var message = $('.chat__input input').val();
	$('.chat__input input').val('');
	$('.chat__input input').focus();

	if (message != '') {
		sendMessage(message);
	}
}

function onButtonClick (event) {
	var $btn = $(this);

	var multiple = $btn.parent().attr('data-multiple');

	// Multiple choice
	if (multiple == 'true') {

		// Multiple choice: confirm
		if ($btn.hasClass('btn-confirm')) {
			var msg = '';
			$('.btn.active', $btn.parent()).each(function(){
				msg = msg + $(this).html() + ', ';
			});

			msg = msg.substring(0, msg.length - 2);
			sendMessage(msg);

		// Multiple choice: toggle
		} else {
			$btn.toggleClass('active');

			var minimum = $btn.parent().attr('data-minimum');
			var active_count = $('.btn.active' ,$btn.parent()).length;
			if (active_count >= minimum) {
				$('.btn-confirm', $btn.parent()).removeClass('disabled');
			} else {
				$('.btn-confirm', $btn.parent()).addClass('disabled');
			}
		}

	// Single choice
	} else {
		if (!$btn.hasClass('disabled')) {
			sendMessage($btn.html());
		}

		$btn.parent().addClass('disabled');
		$('.btn', $btn.parent()).addClass('disabled');
	}

	$('.chat__input input').focus();
}



/* API */

function sendMessage (message) {
	addMessage(message);

	$('.msg-buttons').addClass('disabled');
	$('.msg-buttons .btn').addClass('disabled');

	var promise = client.textRequest(message, {sessionId: client.getSessionId()});
	promise.then(handleResponse).catch(handleError);
}

function handleResponse (serverResponse) {
	var reply = serverResponse.result.fulfillment.speech;
	var received = serverResponse.result.fulfillment.messages[1];

	if (Cookies.get('chatbot_session') == undefined) {
		Cookies.set('chatbot_session', client.getSessionId());
	}

	addMessage(reply, true);

	if (received) {
		if (received.payload.buttons) {
			switch (received.payload.buttons) {
				case 'travellertypes':
					getTravellerTypes();
				break;
				case 'interests':
					getInterests();
				break;
			}
		} else if (received.payload.buildlink) {
			buildLink(received.payload.buildlink);
		}
	}
}

function handleError (serverError) {
	console.log(serverError);
}


function getInterests() {
	// Hier moet custom api call komen
	$.ajax({
		method: "GET",
		url: "/api/v2/1/en/chat/traveller-interests",
		dataType: "json",
		async: false
	})
	.done(function( data ) {
		addButtons(data.entries, true, 3)
	})
	.fail(function() {
		// FALLBACK
		return 'Error'
	})
}

function getTravellerTypes() {
	// Hier moet custom api call komen
	$.ajax({
		method: "GET",
		url: "/api/v2/1/en/chat/traveller-type",
		dataType: "json",
		async: false
	})
	.done(function( data ) {
		addButtons(data.entries)
	})
	.fail(function() {
		// FALLBACK
		return 'Error'
	})
}

function buildLink(data) {
	var interest_ids = data.interests.replace(" and ",", ").split(", ");
	var traveller_id = data.travellertypes;

	var link = '/en/personal-packages?person='+traveller_id;

	for (var i =0; i < interest_ids.length; i++) {
		link = link + '&interests[]=' + interest_ids[i];
	}

	addLinkButton('View packages', link);
}



/* visual functions */

function addMessage (message, is_reply) {
	if (is_reply === undefined) is_reply = false;

	if (is_reply) {
		$('.chat__output').append('<div class="msg msg--reply">'+message+'</div>')
	} else {
		$('.chat__output').append('<div class="msg">'+message+'</div>');
	}

	$('.chat__output').scrollTop($('.chat__output')[0].scrollHeight);
	saveChat();
}

function addButtons (buttons, multiple, minimum) {
	if (multiple === undefined) multiple = false;
	if (minimum === undefined) minimum = 0;

	var html = '';

	html += '<div class="msg-buttons" data-multiple="'+multiple+'" data-minimum="'+minimum+'">';
	for (var i=0; i < buttons.length; i++) {
		var button_text = buttons[i].value;
		if (buttons[i].synonyms && buttons[i].synonyms.length > 0) {
			button_text = buttons[i].synonyms[0]
		}

		html += '<button class="btn">'+button_text+'</button>';
	}

	// Confirm button
	if (multiple == true) {
		html += '<button class="btn btn-confirm disabled">OK</button>';
	}

	html += '</div>';

	$('.chat__output').append(html);
	$('.chat__output .btn').click(onButtonClick);

	$('.chat__output').scrollTop($('.chat__output')[0].scrollHeight);
	saveChat();
}

function addLinkButton(text, link) {

	var html = '<div class="msg-buttons">';
	html += '<a href="'+link+'" class="btn btn-link">'+text+'</a>';
	html += '</div>';


	$('.chat__output').append(html);
	$('.chat__output').scrollTop($('.chat__output')[0].scrollHeight);
	saveChat();
}



/* web storage functions */

function saveChat() {
	if (storageAvailable('localStorage')) {
		var chat_html = $('.chat__output').html();
		localStorage.setItem('chat_html', chat_html);
	}
}

function loadChat() {
	if (storageAvailable('localStorage')) {
		var chat_html = localStorage.getItem('chat_html');
		$('.chat__output').html(chat_html);

		$('.chat__output .btn').click(onButtonClick);
		$('.chat__output').scrollTop($('.chat__output')[0].scrollHeight);
	}
}

function clearChat() {
	if (storageAvailable('localStorage')) {
		localStorage.removeItem('chat_html');
	}
	$('.chat__output').html('');
}

function storageAvailable(type) {
	try {
		var storage = window[type], x = '__storage_test__';
		storage.setItem(x, x);
		storage.removeItem(x);
		return true;
	}
	catch(e) {
		return e instanceof DOMException && (
			// everything except Firefox
			e.code === 22 ||
			// Firefox
			e.code === 1014 ||
			// test name field too, because code might not be present
			// everything except Firefox
			e.name === 'QuotaExceededError' ||
			// Firefox
			e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
			// acknowledge QuotaExceededError only if there's something already stored
			storage.length !== 0;
	}
}