// Set site info
// var siteinfo = document.getElementsByName("siteinfo")[0];
// var site_id = siteinfo.getAttribute("data-siteid");
// var lang = siteinfo.getAttribute("data-sitelang");

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$(document).ready(function ()
{
	/*$('#hotel-stars').barrating({
    	theme: 'css-stars',
    	allowEmpty: null
	});*/

	$('#hotel-stars input[name="stars[]"]').change(update_hotels_filter);
	$('#hotel-boutique').change(update_hotels_filter);

	$('[data-action="toggle-choice"]').change(function(){
		var toggle_on = $(this).attr('data-toggle-on');
		var toggle_div = $(this).attr('data-toggle');

		if($(this).val() == toggle_on){
			$('#'+toggle_div).show();
		}else{
			$('#'+toggle_div).hide();
		}
	});

	$('[data-action="toggle-choice"]').change();

	$('input.guide-check').change(function(){
		if ($(this).attr('name') == 'no_guide') {
			$('input.guide-check').prop('checked', false);
			$('input.guide-check[name="no_guide"]').prop('checked', true);
		}else{
			$('input.guide-check[name="no_guide"]').prop('checked', false);
		}
	});

	$('input[name="hotel"]').change(function(){
		if ($(this).val() == 0) {
			$('[data-component="hotel-details"]').fadeOut();
			$('input[name="hotel"]:checked').prop( "checked", false );
			$(this).prop( "checked", true );
			return false;
		}else{
			$('input[name="hotel"][value="0"]').prop( "checked", false );
		}
		if ($('input[name="hotel"]:checked').length > 0) {
			$('[data-component="hotel-details"]').fadeIn();
				var roomtypes = JSON.parse($(this).attr('data-roomtypes'));
				$('[data-roomtype]').hide();
				for (var i = roomtypes.length - 1; i >= 0; i--) {
					$('[data-roomtype="'+roomtypes[i]+'"]').show();
				}
		}else{
			$('[data-component="hotel-details"]').fadeOut();
		}
	});

	if ($('input[name="hotel"]:checked').length > 0) {
		$('input[name="hotel"]:checked').change();
	}

	$('input[name="airline"]').change(function(){
		if ($(this).val() === '') {
			$('[data-component="flight-details"]').fadeOut();
		}else{
			if ($(this).val() === '0') {
				$('[data-component="custom-airline"]').fadeIn();
			}else{
				$('[data-component="custom-airline"]').fadeOut();
			}
			$('[data-component="flight-details"]').fadeIn();
		}
	});

	if ($('input[name="airline"]:checked').length > 0) {
		$('input[name="airline"]:checked').change();
	}

	$('.mydatepicker').datepicker({
	    language: 'en',
	    minDate: new Date() // Now can select only dates, which goes after today
	})
	$('[data-favorite]').click(function()
	{
		var id = $(this).attr('data-favorite');
		var arrival = $(this).attr('data-arrival');
		var adults = $(this).attr('data-adults');
		var children = $(this).attr('data-children');
		var elderly = $(this).attr('data-elderly');

		$.ajax({
			url: "/favorites",
			dataType: "json",
			method: "POST",
			data: {
				id:id,
				arrival:arrival,
				adults:adults,
				children:children,
				elderly:elderly,
			},
		})
		.done(function( data ) {

			var count = Object.keys(data).length;

			if( count > 0 )
			{
				$('[data-favorites-count]').html(count);
				$('[data-favorites-count]').removeClass('d-none');
			}
			else{
				$('[data-favorites-count]').addClass('d-none');
			}

			if( data[id] != undefined )
			{
				$('[data-favorite="'+id+'"] i').addClass('icon-heart');
				$('[data-favorite="'+id+'"] i').removeClass('icon-heart-empty');
			}
			else{
				$('[data-favorite="'+id+'"] i').addClass('icon-heart-empty');
				$('[data-favorite="'+id+'"] i').removeClass('icon-heart');

				$('[data-favorite="'+id+'"] i').closest('.pack').parent().hide();

				if( count == 0 )
				{
					location.reload();
				}
			}
		});
	});

	$('.slider-packs').slick({
		slidesToShow: 3,
		slidesToScroll: 3,
		prevArrow: '.slider-packs-prev',
		nextArrow: '.slider-packs-next',
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 420,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	});

	$('.slider-banner').slick({
		arrows: false,
		autoplay: true,
  		autoplaySpeed: 4000,
  		fade: true
	});
	$('.slider-hotel').slick({
		arrows: false,
		autoplay: true,
  		autoplaySpeed: 4000
	});

	$('.slider-must').slick({
		arrows: false,
		autoplay: true,
  		autoplaySpeed: 3000
	});

	$('.slider-hotel').slick({
		arrows: false,
		autoplay: true,
  		autoplaySpeed: 3000
	});


	$('.slider-testi').slick({
		prevArrow: '.slider-testi-prev',
		nextArrow: '.slider-testi-next'
	});

	$('.slider-sponsors').slick({
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
		slidesToShow: 8,
		slidesToScroll: 8,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 5
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4
				}
			},
			{
				breakpoint: 420,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			}
		]
	});

	/*$('[data-hover]').mouseover(function(){
		var id = $(this).attr('data-id');
		if ($('.chk-persona:checked').val() != id) {
			$(this).attr('src', $(this).attr('data-hover'));
		}
		$('.persona-info').hide();
		$('.persona-info[data-id="'+id+'"]').show();
	});

	$('[data-hover]').mouseout(function(){
		check_persona_text();
	});*/

	$('.btn-toggle-booking').click(function(){
		$('.toggle-booking').slideDown();
		$(this).parent().fadeOut();
	});

	$('.btn-toggle-booking-bottom').click(function(){
		$('html,body').animate({
			scrollTop: $('#book-package').offset().top
		}, 300);
		$('.toggle-booking').slideDown();

		$('.btn-toggle-booking').parent().fadeOut();
		$('.btn-toggle-booking-bottom').parent().fadeOut();
	});

	$('.chk-persona').change(check_persona_text);



	/*
	 *	RICH-CONTENT
	 */

	// Lightbox
	$('.lightbox').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true,
		helpers : {
			overlay : {
	            locked : false
	        }
		}
	});
	$('.lightbox-media').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true,
		helpers : {
			media : {},
			overlay : {
	            locked : false
	        }
		}
	});



	// Youtube/Vimeo thumbs
	$(".rc-video__thumb").not('.lightbox-media').click( startVideo );
	$(".rc-video__overlay").not('.lightbox-media').click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			if( src.indexOf('?') > -1 )
			{
				iframe.attr('src', src + '&autoplay=1');
			}
			else
			{
				iframe.attr('src', src + '?autoplay=1');
			}
		}

		$('.rc-video__overlay', block.parent()).fadeOut(150);
		$('.rc-video__thumb', block.parent()).fadeOut(150);
	}

	// Quote slider
	$('.rc-quote-slider__slick').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});

	$('#filter_form_get input').change(function(){
		$('#filter_form_get').submit();
	});

	$('#filter_form input').change(function(){
		var params = $('#filter_form').serialize();
		var arr = get_params(params);
		$('[data-filter]').attr('data-filter', JSON.stringify(arr));
		fetch_itemslist(1);
		check_interests();
	});
	check_interests();

	check_home_form();

	$('.filter-form input').change(check_home_form);
});

function update_hotels_filter(){

	var to_show = [];
	$('.hotel').hide();
	if ($(this).val() == 0) {
		to_show = $('.hotel');
		$('#hotel-stars input[name="stars[]"]:checked').prop('checked', false);
		$(this).prop('checked', true);
	}else{
		to_show[0] = $('.hotel input[value="0"]').eq(0).parent();
		$('#hotel-stars input[value="0"]').prop('checked', false);
		var boutique = $('#hotel-boutique').is(':checked') ? 1 : 0;
		$('#hotel-stars input[name="stars[]"]:checked').each(function(){
			$('.hotel[data-stars="'+$(this).val()+'"]').each(function(){
				if (boutique && $(this).attr('data-boutique') != boutique) {
					return true;
				}
				to_show[$(this).index()] = this;
			});
		});
	}

	show_me(to_show);
}

var show_me_timeout = null;
function show_me(items){
	var compactArray = items.filter(function (item) {
	    return item !== undefined;
	});

	if (show_me_timeout != null) {
		clearTimeout(show_me_timeout);
	}

	for (var i = 0; i < compactArray.length; i++) {
		var delay = i*100;
		show_me_timeout = setTimeout(function(item){
			$(item).fadeIn().delay(delay);
		}, delay, compactArray[i]);
	}
}

function check_home_form(){
	var check = true;
	if ($('.filter-form input.chk-persona:checked').length < 1) {
		check = false;
	}

	check_home_interests();

	if ($('.filter-form input[name="interests[]"]:checked').length < 3) {
		check = false;
	}

	if (check) {
		$('.filter-form button[type=submit]').attr('disabled', null);
	}else{
		$('.filter-form button[type=submit]').attr('disabled', 'disabled');
	}
}

function check_home_interests(){
	if($('[name="interests[]"]:checked').length == 5){
		$('[name="interests[]"]:not(:checked)').attr('disabled', 'disabled');
	}else{
		$('.disabled-checkbox').remove();
		$('[name="interests[]"]').attr('disabled', null);
	}
}

function check_interests(){
	if ($('[name="interests[]"]:checked').length <= 3){
		$('[name="interests[]"]').attr('disabled', null);
		$('[name="interests[]"]:checked').attr('disabled', 'disabled');
		$('[name="interests[]"]:checked').each(function(i, el){
			if ($('.disabled-checkbox[value="' + $(el).val() + '"]').length == 0) {
				$(el).after('<input type="hidden" value="' + $(el).val() + '" name="interests[]" class="disabled-checkbox" />');
			}
		});
	}else if($('[name="interests[]"]:checked').length == 5){
		$('[name="interests[]"]:not(:checked)').attr('disabled', 'disabled');
	}else{
		$('.disabled-checkbox').remove();
		$('[name="interests[]"]').attr('disabled', null);
	}
}

function get_params(str){
	var match = str.match(/[^=&?]+\s*=\s*[^&#]*/g);
	var obj = {};

	for (var i = match.length; i--;) {
		var spl = match[i].split("=");
		if (spl[0].indexOf("%5B%5D") !== -1) {
			var name = spl[0].replace("%5B%5D", "");
			var value = spl[1];

			obj[name] = obj[name] || [];
			obj[name].push(value);
		}else{
			var name = spl[0];
			var value = spl[1];
			obj[name] = value;
		}
	}

	return obj;
}

function check_persona_text(){
	$('.persona-info').hide();

	if ($('.chk-persona:checked').length > 0) {
		var id = $('.chk-persona:checked').val();
		$('.persona-info[data-id="'+id+'"]').show();

		$('img[data-id]').each(function(i, el){
			if ($(el).attr('data-id') != id) {
				$(el).attr('src', $(el).attr('data-src'));
			} else {
				$(el).attr('src', '');
				$(el).attr('src', $(el).attr('data-hover'));
			}
		});

		/*if ($('img[data-id="'+id+'"]').attr('src') != $('img[data-id="'+id+'"]').attr('data-hover')) {
			//reset_personas();
			$('img[data-id="'+id+'"]').attr('src', $('img[data-id="'+id+'"]').attr('data-hover'));
		}*/
	} else {
		reset_personas();
	}
}

function reset_personas(){
	$('img[data-id]').each(function(i, el){
		$(el).attr('src', $(el).attr('data-src'));
	});
}

$(window).on('load', function ()
{
	// Image slider (options in data-slick)
	$('.rc-image-slider__slick').slick();


	/*
	 *	SCROLL REVEAL
	 */

	window.sr = ScrollReveal({ scale: 1, duration: 350 });

	sr.reveal('.sr-top', {origin: 'top'}, 150);
	sr.reveal('.sr-left', {origin: 'left'}, 150);
	sr.reveal('.sr-right', {origin: 'right'}, 150);
	sr.reveal('.sr-bottom', {origin: 'bottom'}, 150);
});